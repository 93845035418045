import { priceFormatter } from '@/utils/';
import { displayPrice } from './helper';

export const priceFn = ({ max_price, min_price, max_weight, min_weight }, currencySymbol) => {
  let text = '';
  if (max_price === 0) {
    text = displayPrice(max_price / max_weight);
  } else if (min_price != max_price || min_weight != max_weight) {
    const first = priceFormatter('', displayPrice(min_price / min_weight));
    const last = priceFormatter('', displayPrice(max_price / max_weight));
    if (first > last) {
      text = `${last} - ${currencySymbol} ${first}`;
    } else {
      text = `${first} - ${currencySymbol} ${last}`;
    }
  } else {
    text = priceFormatter('', displayPrice(min_price / min_weight));
  }
  return `${currencySymbol} ${text} /KG`;
};

export const weightFn = ({ min_weight, max_weight }) => {
  let text = '';
  if (min_weight != max_weight) text = `${min_weight} - ${max_weight}`;
  else text = min_weight;
  return `${text} KG`;
};

export const priceDiscountFn = ({ max_price, min_price }, currencySymbol) => {
  let text = '';
  if (max_price === 0) {
    text = priceFormatter('', displayPrice(max_price));
  } else if (min_price !== max_price) {
    text = `${priceFormatter('', displayPrice(min_price))} - ${currencySymbol} ${priceFormatter(
      '',
      displayPrice(max_price)
    )}`;
  } else {
    text = priceFormatter('', displayPrice(min_price));
  }
  return `${currencySymbol} ${text}`;
};

export const marketPriceFn = ({ min_market_price, max_market_price }, currencySymbol) => {
  let text = '';
  if (min_market_price == max_market_price) {
    text = priceFormatter('', displayPrice(min_market_price));
  } else {
    text = `${priceFormatter('', displayPrice(min_market_price))} - ${currencySymbol} ${priceFormatter(
      '',
      displayPrice(max_market_price)
    )}`;
  }
  return `${currencySymbol} ${text}`;
};

export const discountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  if (min_market_price == max_market_price) {
    return `${minDiscount.toFixed(2)}%`;
  }

  return `${minDiscount.toFixed(0)}% - ${maxDiscount.toFixed(0)}%`;
};

export const hasDiscountFn = ({ min_market_price, max_market_price, min_price, max_price }) => {
  let minDiscount = 0;
  let maxDiscount = 0;
  if (min_market_price > min_price) {
    minDiscount = ((min_market_price - min_price) / min_market_price) * 100;
  }

  if (max_market_price > max_price) {
    maxDiscount = ((max_market_price - max_price) / max_market_price) * 100;
  }

  return !!(minDiscount || maxDiscount);
};
