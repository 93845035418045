<template>
  <product-detail-skeleton v-if="!state.isShow && !opacity" />
  <ion-page class="detail-product" v-else>
    <ion-header
      class="ion-no-border"
      mode="ios"
      :class="['header-product', state.isShowHeader || !state.isOnline ? 'animation' : '']"
    >
      <ion-toolbar class="toolbar-header pl-1 pr-2">
        <ion-icon @click="backHome" size="large" slot="start" :icon="chevronBackOutline"></ion-icon>
        <ion-icon slot="end" size="large" :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        <ion-label class="label-header-product">{{ state.item?.name }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content
      ref="productDetailContent"
      class="modal-content"
      :scroll-events="true"
      @ionScroll="handleShowHeader($event)"
      :id="`product-details-${state.item?.id}`"
    >
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card v-if="!state.isOnline" class="mx-0 mt-5rem px-3 py-3 shadow border-rad-0">
        <ion-card-content class="pa-0">
          <div class="text-center mt-5">
            <ion-text class="fs-2">{{ $t('page_taking_longer') }}</ion-text>
            <div class="mt-4 d-flex column-center">
              <ion-label class="fw-600 mb-2">{{ $t('pull_to_refresh') }}</ion-label>
              <ion-icon color="primary fs-2rem" :icon="arrowDownOutline"></ion-icon>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <div v-if="state.isOnline">
        <div
          style="margin-top: 3.1rem"
          v-show="!state.isShowHeader"
          :class="['back-btn', !state.isShowHeader && 'hidden-back']"
        >
          <ion-icon :icon="chevronBackOutline" @click="backHome"></ion-icon>
          <ion-icon :icon="shareSocialOutline" @click="openSocialSharing"></ion-icon>
        </div>
        <div class="product-image">
          <td-image
            class="no-image-wrapper"
            :image="state.item?.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div v-if="state.isProductOos" class="blend-img"></div>
          <div v-if="state.isProductOos" class="image-off-oss"></div>
          <div v-if="state.isProductOos" class="centered-oos-label">
            <p class="oos">{{ $t('out_of_stock') }}</p>
          </div>
        </div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="12" class="ion-no-padding">
              <ion-label class="label-tenant">{{ state.item?.tenant?.tenant?.name }}</ion-label>
              <ion-label
                class="label-product"
                :style="{ 'font-size': fontSize + 'px', 'line-height': lineHeight + 'px' }"
                ref="refNameProduct"
                >{{ state?.item?.name }}</ion-label
              >
              <ion-label v-if="state.item?.halal" class="label-halal">{{ $t('sync_state.halal') }}</ion-label>
              <ion-label v-if="state.item?.halal !== null && !state.item?.halal" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </ion-col>
            <ion-col size="12" v-if="!needQuotationRequest && allowAttach" class="ion-no-padding">
              <ion-label class="label-price" color="primary">{{ price }}</ion-label>
              <ion-label class="label-kg mb-2">
                {{ price !== `${currencySymbol} NaN` && price ? priceKgBelowTotalPrice : '' }}
              </ion-label>
            </ion-col>
            <ion-col size="12" v-if="needQuotationRequest" class="ion-no-padding mb-2"> </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack">{{ $t('packaging_size') }}</ion-label>
              <ion-label class="label-weight"> {{ packagingSize }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label class="label-pack">{{ $t('weight') }}</ion-label>
              <ion-label class="label-weight">{{ weight }}</ion-label>
              <tooltip v-if="state.skusSelected?.is_catch_weight" />
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack">{{ $t('description') }}</ion-label>
              <ion-label ref="refDescription" :class="['label-weight', state.isShowMore ? 'overflow' : '']"
                >{{ state.item?.description ?? '' }}
              </ion-label>
              <ion-label
                v-if="state.isDisplayShowMore"
                @click="state.isShowMore = !state.isShowMore"
                class="label-showmore"
                >{{ state.isShowMore ? $t('show_more') : $t('show_less') }}</ion-label
              >
            </ion-col>
            <ion-col size="12" class="mt-2 ion-no-padding">
              <ion-label class="label-pack">{{ $t('category_sub') }}</ion-label>
              <div class="form-category my-1 mb-2">
                <ion-label class="label-category-main">{{
                  state.item?.categories?.main_categories?.name
                }}</ion-label>
                <ion-label class="label-category-sub ml-1">{{
                  state.item?.categories?.sub_categories?.name
                }}</ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div
          class="bg-gray"
          v-if="
            (state.item.is_single_variant &&
              (state.item.skusData[0]?.is_out_of_stock || state.item.skusData[0]?.is_purchased)) ||
            isShowRemaining
          "
        ></div>
        <ion-grid class="px-4">
          <ion-row>
            <ion-col size="4" class="ion-no-padding">
              <ion-label class="label-pack" color="grey" v-if="!state.item.is_single_variant">{{
                $t('variations')
              }}</ion-label>
              <div>
                <ion-badge
                  slot="start"
                  class=""
                  color="primary"
                  fill="outline"
                  v-if="
                    state.item.is_single_variant &&
                    state.item.skusData[0]?.is_purchased &&
                    !state.item.skusData[0]?.is_out_of_stock
                  "
                  >{{ $t('product_detail.buy_again') }}</ion-badge
                >
              </div>
            </ion-col>
            <ion-col v-if="isShowRemaining" size="8" class="ion-no-padding d-flex flex-column align-end">
              <ion-text v-if="!state.skusSelected.is_order_by_weight" class="qty-label" color="danger">
                {{ $t('Quantity') }}:
                {{ $t('last_remaming_item', { remainingQuantity }) }}
              </ion-text>
              <ion-text v-else class="qty-label" color="danger">
                {{ $t('Quantity') }}:
                {{ $t('last_remaming_weight_item', { remainingQuantity }) }}
              </ion-text>
            </ion-col>
            <ion-col
              class="ion-no-padding d-flex flex-column align-end"
              v-if="state.item.is_single_variant && state.item.skusData[0]?.is_out_of_stock"
            >
              <ion-row>
                <ion-text class="qty-label"> {{ $t('product_detail.stock') }}: </ion-text>
                <ion-text class="qty-label ml-2" color="danger">
                  {{ $t('product_detail.out_of_stock') }}
                </ion-text>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row class="mt-2" v-if="!state.item.is_single_variant">
            <ion-col
              class="ion-no-padding mt-1"
              size="12"
              v-for="(specs, keyName) in getSpecsByKey"
              :key="keyName"
            >
              <ion-row>
                <ion-label class="label-keyname">{{ keyName }}</ion-label>
                <ion-label v-if="state.isOutOfStock && allowAttach" class="oos-marker">{{
                  $t('out_of_stock')
                }}</ion-label>
              </ion-row>
              <ion-label class="label-select-another">{{
                state.skusSelected &&
                state.skusSelected.sku_id !== 0 &&
                JSON.stringify(state.skusSelected?.specs).includes(`${keyName}`) &&
                specs.length > 1 &&
                state.isOutOfStock
                  ? ' ' + $t('select_another_option')
                  : ''
              }}</ion-label>
              <div class="d-flex flex-wrap mt-1">
                <div v-for="spec in specs" :key="spec.value" class="form-btn">
                  <ion-badge
                    slot="start"
                    class="oos-badge is-oos"
                    color="medium"
                    v-if="showOosAndBuyAgainStatus(spec.value).showOos"
                    >{{ $t('product_detail.sold_out') }}</ion-badge
                  >
                  <ion-badge
                    slot="start"
                    class="oos-badge buy-again"
                    color="primary"
                    fill="outline"
                    v-if="showOosAndBuyAgainStatus(spec.value).showBuyAgain"
                    >{{ $t('product_detail.buy_again') }}</ion-badge
                  >
                  <ion-button
                    mode="ios"
                    v-if="
                      state.specsSelected.includes(spec.value) &&
                      !showOosAndBuyAgainStatus(spec.value).showOos
                    "
                    fill="outline"
                    class="variant-chip btn-primary mt-2"
                    @click="unCheckSpecItem(spec.value)"
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    class="variant-chip btn-disable mt-2"
                    v-else-if="
                      (state.specsInvolved.length > 0 && !state.specsInvolved.includes(spec.value)) ||
                      showOosAndBuyAgainStatus(spec.value).showOos
                    "
                    fill="outline"
                    disabled
                  >
                    {{ spec.value }}
                  </ion-button>
                  <ion-button
                    mode="ios"
                    v-else
                    fill="outline"
                    class="variant-chip btn-grey mt-2"
                    @click="checkSpecItem(spec.value)"
                  >
                    {{ spec.value }}
                  </ion-button>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bg-gray"></div>
        <div ref="similiarProduct" class="similiar-product-bg">
          <product-recommend-list
            v-if="(showSimiliarProduct || state.isProductOos) && similiarProductItems?.length > 0"
            :productItems="getSimiliarProductsData"
            :user="state.user"
            @select-similar-product="onSelectSimilarProduct"
            @explore-other-product="onExploreOtherProducts"
          ></product-recommend-list>
        </div>
      </div>
    </ion-content>

    <ion-footer v-if="state.isOnline" class="ion-no-border footer">
      <ion-toolbar class="ion-no-padding">
        <div class="ml-2">
          <div v-if="needQuotationRequest && !state.isOutOfStock" class="d-flex align-center">
            <div class="flex-grow-1">
              <div v-if="state.skusSelected?.quotation_status === QUOTATION_STATUS.REQUESTED">
                <div class="btn-requested mr-0">
                  <ion-icon :icon="paperPlaneOutline" class="icon"></ion-icon>
                  <span class="ml-1">{{ $t('requested') }}</span>
                </div>
              </div>
              <div v-else>
                <btn-request-quotation
                  :allowAttach="allowAttach"
                  :selectedCompanyId="state.selectedCompany.id"
                  :tenantId="state.item.tenant_id"
                  :userId="state.user.id"
                  :skuId="state.skusSelected?.sku_id"
                  :customer="state.selectedCompany.name || state.selectedCompany.branch"
                  @update-local-request-quotation="updateLocalRequestQuotation"
                />
              </div>
            </div>

            <!-- favorite -->
            <div>
              <btn-favorite
                :isFavorite="state.skusSelected?.is_favorite ?? false"
                :selectedCompany="state.selectedCompany"
                :skusSelected="state.skusSelected"
                :allowAttach="allowAttach"
                @update-local-favorite="updateLocalFavorite"
              />
            </div>
          </div>

          <!-- no needQuotationRequest -->
          <div v-else>
            <ion-grid class="ion-no-padding" v-if="allowAttach">
              <ion-row class="footer-input" :class="{ 'mb-3': !isDisableAddButton }">
                <ion-col size="6" class="pl-4 mt-2 mb-1">
                  <div class="d-flex align-center mb-2">
                    <ion-label class="label-total-price">{{ $t('total_price') }}</ion-label>
                    <ion-label class="label-special-price" v-if="isSpecialPrice">
                      {{ $t('special_price') }}</ion-label
                    >
                  </div>
                  <div>
                    <ion-label v-if="!state.isOutOfStock" class="label-sum-price">
                      <span v-if="state.skusSelected?.is_order_by_weight">
                        {{ showPriceWeight }}
                      </span>
                      <span v-else>
                        {{ showPriceQuantity }}
                      </span>
                    </ion-label>
                    <ion-label v-else class="label-sum-price">-</ion-label>
                  </div>
                  <div>
                    <ion-label v-if="!state.isOutOfStock" class="label-price-kg">{{
                      priceKgBelowTotalPrice
                    }}</ion-label>
                    <ion-label v-else class="label-price-kg">-</ion-label>
                  </div>
                </ion-col>
                <ion-col size="6" class="d-flex flex-column align-items-center pr-4 mb-1 ion-no-padding">
                  <div class="d-flex justify-end" v-if="isBargainPrice">
                    <ion-button
                      @click="toggleModalBargainPrice"
                      fill="outline"
                      size="small"
                      class="btn-bergain-price"
                      :color="isPendingApprove ? 'medium' : ''"
                      :disabled="isPendingApprove"
                    >
                      {{ isPendingApprove ? $t('pending_approve') : $t('bargain_price') }}</ion-button
                    >
                  </div>
                  <div v-if="state.skusSelected?.is_order_by_weight" class="d-flex align-self-center mt-2">
                    <ion-input
                      type="number"
                      :readonly="state.isOutOfStock && allowAttach"
                      class="input-weight"
                      :value="formattedWeight"
                      @ionInput="onInputWeight"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || (event.target.value.indexOf('.') === -1 && event.charCode === 46)"
                    />
                    <span class="ml-1 mt-2">{{ $t('kg') }}</span>
                  </div>
                  <div class="d-flex cnt-weight h-100 form-input d-flex justify-end" v-else>
                    <div class="align-self-center text-center">
                      <div v-if="!state.isOutOfStock" class="cnt-icon-q mr-2" @click="minusQuantity">
                        <ion-icon :icon="remove"></ion-icon>
                      </div>
                      <div v-else class="cnt-icon-q-outline mr-2" @click="minusQuantity">
                        <ion-icon class="icon-q" :icon="removeCircleOutline"></ion-icon>
                      </div>
                    </div>
                    <div v-if="!state.isOutOfStock" size="5" class="align-self-center text-center">
                      <input
                        v-model="state.orderQuantity"
                        type="number"
                        maxlength="4"
                        class="input-weight"
                        @input="orderQuantityChange"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      />
                    </div>
                    <div v-else size="5" class="align-self-center text-center">
                      <ion-input
                        readonly
                        v-model.number="state.outOfStockQty"
                        type="text"
                        class="input-oos"
                      />
                    </div>
                    <div class="align-self-center text-center">
                      <div
                        v-if="!state.isOutOfStock"
                        class="ml-2"
                        :class="{
                          'cnt-icon-q-disabled': disabledAddQuantity,
                          'cnt-icon-q': !disabledAddQuantity
                        }"
                        @click="addQuantity"
                      >
                        <ion-icon class="icon-q" :icon="add"></ion-icon>
                      </div>
                      <div v-else class="cnt-icon-q-outline ml-2" @click="addQuantity">
                        <ion-icon :icon="addCircleOutline"></ion-icon>
                      </div>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="7" />
                <ion-col v-if="isDisableAddButton" size="5" class="ion-no-padding">
                  <ion-text color="danger">{{
                    $t('soldInUnits', { number: state.skusSelected?.increment_qty })
                  }}</ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="d-flex align-center">
              <!-- add to cart -->
              <div class="flex-grow-1 btn-add-to-card">
                <div>
                  <ion-button
                    v-if="showAddCartAndSelectVariation"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    :fill="showAddCartAndSelectVariation ? 'solid' : 'outline'"
                    :disabled="
                      isDisableAddButton ||
                      isDisabledAddToCartByWeight ||
                      !allowAttach ||
                      disableOrderByWeight
                    "
                    :color="!colorGrayBtn ? 'primary' : 'medium'"
                    expand="block"
                    @click="handleCheckLatetestPrice"
                  >
                    <span class="ml-1" v-if="!state.isAddToCartLoading">
                      {{ allowAttach ? $t('add_to_cart') : $t('select_variation') }}
                    </span>
                    <ion-spinner name="crescent" v-else></ion-spinner>
                  </ion-button>
                  <!-- <ion-button
                    v-if="showOosAndSelectOtherVariant && oosList?.oosList?.otherVariant.length > 0"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    fill="solid"
                    color="primary"
                    expand="block"
                    @click="setOtherVariantModal(true)"
                  >
                    <span class="ml-1"> {{ $t('select_other_variant') }}</span>
                  </ion-button> -->
                  <ion-button
                    v-if="showOosAndSelectOtherVariant"
                    tabindex="-1"
                    class="btn-add-to-cart mr-0"
                    fill="solid"
                    :disabled="true"
                    color="medium"
                    expand="block"
                  >
                    <span v-if="!checkOtherVariation" class="ml-1"> {{ $t('out_of_stock') }}</span>
                    <ion-spinner v-else name="dots"></ion-spinner>
                  </ion-button>
                  <div v-if="showQuotation">
                    <div v-if="state.skusSelected?.quotation_status === QUOTATION_STATUS.REQUESTED">
                      <div class="btn-requested mr-0">
                        <ion-icon :icon="paperPlaneOutline" class="icon"></ion-icon>
                        <span class="ml-1">{{ $t('requested') }}</span>
                      </div>
                    </div>
                    <btn-request-quotation
                      v-else
                      :allowAttach="allowAttach"
                      :selectedCompanyId="state.selectedCompany.id"
                      :tenantId="state.item.tenant_id"
                      :userId="state.user.id"
                      :skuId="state.skusSelected?.sku_id"
                      :customer="state.selectedCompany.name || state.selectedCompany.branch"
                      @update-local-request-quotation="updateLocalRequestQuotation"
                    />
                  </div>
                </div>
              </div>

              <!-- favorite -->
              <div>
                <btn-favorite
                  :isFavorite="state.skusSelected?.is_favorite ?? false"
                  :selectedCompany="state.selectedCompany"
                  :skusSelected="state.skusSelected"
                  :allowAttach="allowAttach"
                  @update-local-favorite="updateLocalFavorite"
                />
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="state.isOpenModalBargainPrice"
      @didDismiss="state.isOpenModalBargainPrice = false"
    >
      <modal-bargain-price
        :packaging_size="packaging_size"
        :weight="weight"
        :item="state.item"
        :skusSelected="state.skusSelected"
        :directPrice="directPrice"
        :directPriceAboveKg="directPriceAboveKg"
        :selectedCompanyId="state.selectedCompany.id"
        :tenantId="state.item.tenant_id"
        :userId="state.user.id"
        :skuId="state.skusSelected?.sku_id"
        :customer="state.selectedCompany.name || state.selectedCompany.branch"
        @offModal="state.isOpenModalBargainPrice = false"
        @handlePendingApprove="handlePendingApprove"
      />
    </ion-modal>
    <ion-modal
      ref="modal-other-variant"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="modal-instruction"
      :is-open="state.isOpenSelectOtherVariant"
      @didDismiss="setOtherVariantModal(false)"
    >
      <modal-other-variant
        @closeSelectOtherVariantModal="setOtherVariantModal(false)"
        :getSpecsOosByKey="getSpecsOosByKey"
        :getSpecsOosById="getSpecsOosById"
        :selectedCompany="state.selectedCompany"
        :user="state.user"
        :oosList="oosList"
        :currencySymbol="currencySymbol"
        :item="state.item"
        @updateSkusfavorite="changeSkuFavorite"
        @refreshFavoriteList="$emit('refresh-favorite-list')"
      />
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenPriceUpdated"
      css-class="modal-price-updated"
      @didDismiss="setOpenModalPriceUpdate(false)"
    >
      <price-update-alert @close-modal="setOpenModalPriceUpdate(false)"></price-update-alert>
    </ion-modal>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import {
  DEFAULT_PRODUCT_IMAGE,
  IMAGE_PATH,
  JOIN_STATUS,
  NOTIFICATION_TYPE,
  QUOTATION_STATUS,
  TYPE_SEARCH,
  USER_TYPE
} from '@/modules/b2b/constants';
import { displayPrice, getTomorrow } from '@/modules/b2b/services/libs/helper';
import { priceFn } from '@/modules/b2b/services/libs/home';
import { ACTIONS as ACTIONS_B2B, ACTIONS as ACTION_OOS } from '@/modules/b2b/store/product/actions';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import {
  CATEGORY_ENABLE_OOS,
  INPUT_QUANTITY_BY_WEIGHT,
  LIMIT_PRODUCT_REMAINING,
  MAX_INPUT
} from '@/modules/shared/constants/';
import { isLargerMaxInput, isQuantityAMultipleOfIncrement, useCheck } from '@/modules/shared/utils/';
import CleverTap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { alertController, toastController, useBackButton } from '@ionic/vue';
import { add, heart, paperPlaneOutline, remove } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import functions from './utils';

// components
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { getCustomerDetailBuyer, getLatestPrice } from '@/modules/b2b/services/graphql';
import PriceUpdateAlert from '../cart/components/PriceUpdateAlert.vue';
import ProductDetailSkeleton from './components/Skeleton.vue';
import ModalBargainPrice from './components/modals/BargainPrice.vue';
import ModalOtherVariant from './components/modals/OtherVariant.vue';
import ProductRecommendList from './components/recommend-product/RecommendedList.vue';
import BtnFavorite from './partials/BtnFavorite.vue';
import BtnRequestQuotation from './partials/BtnRequestQuotation.vue';
// vuex
import { ACTIONS as ACTIONS_CART } from '@/modules/b2b/store/cart/actions';
import { ACTIONS as ACTIONS_NOTIFICATION } from '@/modules/b2b/store/notification/actions';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';
import { useI18n } from 'vue-i18n';
import { createNamespacedHelpers } from 'vuex';
const {
  mapActions: mapActionsProduct,
  mapGetters: mapGettersProduct,
  mapMutations: mapMutationsProduct
} = createNamespacedHelpers('b2b/product');
const { mapGetters: mapGettersOosProduct, mapActions: mapActionsOos } = createNamespacedHelpers(
  'b2b/product'
);
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('b2b/cart');
const { mapActions: mapActionsNotification } = createNamespacedHelpers('b2b/notification');

import {
  addCircleOutline,
  arrowDownOutline,
  checkmarkOutline,
  chevronBackOutline,
  closeOutline,
  removeCircleOutline,
  shareSocialOutline,
  storefrontOutline
} from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProductDetail',
  components: {
    Tooltip,
    BtnFavorite,
    BtnRequestQuotation,
    ProductDetailSkeleton,
    ModalBargainPrice,
    ModalOtherVariant,
    ProductRecommendList,
    PriceUpdateAlert
  },
  setup() {
    const state = reactive({
      user: null,
      buyer: null,
      tenantClassLevel: null,
      specsSelected: [],
      specsInvolved: [],
      skuIdsInvolved: [],
      skusSelected: null,
      selectedCompany: null,
      orderQuantity: 1,
      outOfStockQty: 0,
      isOnline: true,
      orderWeight: null,
      isOutOfStock: false,
      isProductOos: false,
      isShow: false,
      isShowHeader: false,
      isShowMore: true,
      isDisplayShowMore: false,
      item: null,
      selectedTab: '',
      isOpenSelectOtherVariant: false,
      isAddToCartLoading: false,
      isChangeSkuFavorite: false,
      isOpenModalBargainPrice: false,
      oosVariable: {
        mainCategoryId: '',
        isSingleVariant: false,
        productId: null,
        tenantId: null,
        buyerId: null,
        limit: 5,
        offset: 0
      }
    });
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const checkOtherVariation = ref(false);
    const similiarProductItems = ref(null);
    // functions
    const toggleModalBargainPrice = () => (state.isOpenModalBargainPrice = !state.isOpenModalBargainPrice);
    const changeSkusSelected = (sku = { sku_id: 0 }) => {
      state.skusSelected = sku;
      if (sku.weight) {
        state.orderWeight = sku.weight.toFixed(2);
        if (!sku.is_back_order && sku.total_available_quantity > 0) {
          state.orderWeight =
            sku.weight > sku.total_available_quantity
              ? sku.total_available_quantity.toFixed(2)
              : sku.weight.toFixed(2);
        }
      } else {
        state.orderWeight = 0;
      }
    };

    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const openToast = async (message, color = 'primary', position = 'top') => {
      const toast = await toastController.create({
        message,
        position,
        color,
        duration: 700
      });
      return toast.present();
    };
    const showAlert = async ({ header, message }) => {
      const alert = await alertController.create({
        mode: 'ios',
        header,
        message,
        buttons: [t('close')]
      });
      setTimeout(async () => await alert.present(), 200);
    };
    const detectConnection = () => {
      const connection = navigator.onLine;
      if (connection) {
        state.isOnline = true;
      } else {
        state.isOnline = false;
      }
    };
    const handleRefresh = (event) => {
      detectConnection();
      event.target.complete();
    };
    const setOrderQuantity = (value) => {
      state.orderQuantity = value;
    };
    const { getProductDetail } = functions.useGetProductDetail();
    const changeSkuFavorite = () => {
      state.isChangeSkuFavorite = true;
    };
    watch(
      () => state.specsSelected,
      () => {
        const element = document.querySelector(`#product-details-${state.item?.id}`) || null;
        if (element) {
          const scrollHeight = element?.scrollHeight ?? 0;
          element.scrollToPoint(0, scrollHeight, 1000);
        }
        if (state.skusSelected && state.skusSelected.sku_id !== 0) {
          state.skusSelected?.is_out_of_stock ? (state.isOutOfStock = true) : (state.isOutOfStock = false);
          if (state.isOutOfStock) {
            state.oosVariable.mainCategoryId = state.selectedTab;
            state.oosVariable.isSingleVariant = state.item.is_single_variant;
            state.oosVariable.productId = state.item.id;
            state.oosVariable.tenantId = state.item.tenant_id;
            state.oosVariable.buyerId = state.selectedCompany.id;
          }
          state.isChangeSkuFavorite
            ? (state.isChangeSkuFavorite = false)
            : setOrderQuantity(state.skusSelected.increment_qty);
        } else {
          state.isOutOfStock = false;
        }
      }
    );
    // computed
    const currencySymbol = computed(() => {
      return state.user && state.user.country.currency_symbol;
    });
    const packagingSize = computed(() => {
      if (state.skusSelected) {
        const { unit_amount, uom, unit_per_item, unit_per_item_uom } = state.skusSelected;
        if (isItemKGAndNoneUnit(uom, unit_per_item)) {
          return '';
        }
        const itemsInProduct = checkWeightItem(unit_amount, uom);
        const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
        return combineTwoUnit(itemsInProduct, unitsInItem);
      }
      return '';
    });

    const showPriceQuantity = computed(() => {
      return state.skusSelected
        ? priceFormatter(currencySymbol.value, displayPrice(state.skusSelected.price * state.orderQuantity))
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const showPriceWeight = computed(() => {
      return state.skusSelected
        ? priceFormatter(
            currencySymbol.value,
            displayPrice((state.skusSelected.price / state.skusSelected.weight) * +state.orderWeight)
          )
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const weight = computed(() => {
      return state.skusSelected?.sku_id ? state.skusSelected.weight + ' kg' : '';
    });
    const price = computed(() => {
      return state.skusSelected?.sku_id
        ? `${priceFormatter(currencySymbol.value, displayPrice(state.skusSelected.price))}`
        : '-';
    });
    const isDirectPrice = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > 0 &&
        state.skusSelected.direct_price === state.skusSelected.price
      );
    });
    const isSpecialPrice = computed(() => {
      return (
        !state.isOutOfStock &&
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > state.skusSelected.price
      );
    });
    const isBargainPrice = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.direct_price > 0 &&
        !isSpecialPrice.value &&
        state.skusSelected.quotation_status !== 'received'
      );
    });
    const pricekg = computed(() => {
      return priceFn(state.item, currencySymbol);
    });
    const needQuotationRequest = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.quotation_status != QUOTATION_STATUS.RECEIVED &&
        state.skusSelected.sku_id !== 0 &&
        !isDirectPrice.value
      );
    });
    const getSpecsByKey = computed(() => {
      return functions.sortListStrings(functions.getSpecsByKey(state.item));
    });
    const getSkusInfo = computed(() => {
      return functions.getSkusInfo(state.item);
    });
    const allowAttach = computed(() => {
      return state.skusSelected && state.skusSelected.sku_id !== 0 && state.specsSelected.length > 0;
    });
    const isDisabledAddToCartByWeight = computed(() => {
      const weight = +state.orderWeight;
      const quantity = +state.orderQuantity;
      if (state.skusSelected?.is_order_by_weight) {
        return weight <= 0;
      }
      return quantity <= 0;
    });
    const isDisableAddButton = computed(() => {
      return !isQuantityAMultipleOfIncrement({
        quantity: state.orderQuantity,
        increment: state.skusSelected?.increment_qty
      });
    });
    const colorGrayBtn = computed(() => {
      return (
        isDisabledAddToCartByWeight.value ||
        isDisableAddButton.value ||
        state.isOutOfStock ||
        !allowAttach.value ||
        disableOrderByWeight.value
      );
    });
    const packaging_size = computed(() => {
      return state.skusSelected &&
        state.skusSelected.uom !== 'KG' &&
        state.skusSelected.unit_amount &&
        state.skusSelected.uom
        ? state.skusSelected.unit_amount + ' ' + state.skusSelected.uom
        : '';
    });
    const priceKgBelowTotalPrice = computed(() => {
      return `${priceFormatter(
        currencySymbol.value,
        displayPrice(state.skusSelected?.price / state.skusSelected?.weight)
      )}/kg`;
    });
    const isDisableBtn = computed(() => {
      return state.orderQuantity <= 0 || state.orderWeight <= 0;
    });
    const isBackOrder = computed(() => {
      return state.skusSelected?.is_back_order ?? null;
    });
    const disabledAddQuantity = computed(() => {
      return isBackOrder.value || remainingQuantity.value === null
        ? false
        : state.orderQuantity >= MAX_INPUT ||
            state.orderQuantity >= remainingQuantity.value ||
            remainingQuantity.value <= 0 ||
            state.skusSelected.is_out_of_stock;
    });
    const remainingQuantity = computed(() => {
      if (
        state.skusSelected.total_available_quantity < 0 ||
        state.skusSelected.total_available_quantity === null
      ) {
        return MAX_INPUT;
      } else {
        return state.skusSelected?.total_available_quantity ?? MAX_INPUT;
      }
    });
    //check remaining product
    const isShowRemaining = computed(() => {
      return state.skusSelected
        ? !isBackOrder.value && !state.isOutOfStock && remainingQuantity.value <= LIMIT_PRODUCT_REMAINING
        : false;
    });
    const directPrice = computed(() => {
      return state.skusSelected
        ? priceFormatter(currencySymbol.value, displayPrice(state.skusSelected.direct_price))
        : priceFormatter(currencySymbol.value, displayPrice(0));
    });
    const directPriceAboveKg = computed(() => {
      return `${priceFormatter(
        currencySymbol.value,
        displayPrice(state.skusSelected?.direct_price / state.skusSelected?.weight)
      )}/kg`;
    });
    const isPendingApprove = computed(() => {
      return (
        state.skusSelected &&
        state.skusSelected.sku_id !== 0 &&
        state.skusSelected.quotation_status === QUOTATION_STATUS.REQUESTED
      );
    });
    const disableOrderByWeight = computed(() => {
      return state.skusSelected?.is_order_by_weight && state.skusSelected.total_available_quantity
        ? state.orderWeight > state.skusSelected.total_available_quantity
        : false;
    });
    const showSimiliarProduct = computed(() => {
      return (
        state.isOutOfStock &&
        (state.skusSelected?.sku_id !== 0 || state.skusSelected !== null) &&
        state.specsSelected?.length > 0
      );
    });
    const showAddCartAndSelectVariation = computed(() => {
      return !state.isOutOfStock || state.specsSelected.length <= 0;
    });
    const showOosAndSelectOtherVariant = computed(() => {
      return state.isOutOfStock && !needQuotationRequest.value && state.specsSelected.length > 0;
    });
    const showQuotation = computed(() => {
      return state.isOutOfStock && needQuotationRequest.value && state.specsSelected.length > 0;
    });
    const isGetOos = computed(() => {
      return CATEGORY_ENABLE_OOS.indexOf(state.selectedTab) > -1;
    });

    const isOpenPriceUpdated = ref(false);
    const latesNewPrice = ref(null);
    useBackButton(10, () => {
      router.back();
    });
    return {
      state,
      route,
      router,
      storefrontOutline,
      checkmarkOutline,
      chevronBackOutline,
      shareSocialOutline,
      closeOutline,
      removeCircleOutline,
      addCircleOutline,
      toggleModalBargainPrice,
      openToast,
      showAlert,
      packagingSize,
      setOrderQuantity,
      changeSkusSelected,
      getProductDetail,
      changeSkuFavorite,
      checkOtherVariation,
      similiarProductItems,
      JOIN_STATUS,
      QUOTATION_STATUS,
      USER_TYPE,
      NOTIFICATION_TYPE,
      // icons
      paperPlaneOutline,
      heart,
      remove,
      add,
      // computed
      showPriceQuantity,
      showPriceWeight,
      arrowDownOutline,
      weight,
      price,
      isDirectPrice,
      isSpecialPrice,
      isBargainPrice,
      pricekg,
      needQuotationRequest,
      getSpecsByKey,
      getSkusInfo,
      allowAttach,
      isDisabledAddToCartByWeight,
      colorGrayBtn,
      isDisableAddButton,
      packaging_size,
      priceKgBelowTotalPrice,
      isDisableBtn,
      disabledAddQuantity,
      isBackOrder,
      remainingQuantity,
      isShowRemaining,
      directPrice,
      directPriceAboveKg,
      isPendingApprove,
      currencySymbol,
      disableOrderByWeight,
      isOpenPriceUpdated,
      showSimiliarProduct,
      latesNewPrice,
      showAddCartAndSelectVariation,
      showOosAndSelectOtherVariant,
      showQuotation,
      isGetOos,
      handleRefresh,
      detectConnection,
      // social sharing
      Share,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },
  inject: ['$storage'],
  data: () => ({
    fontSize: 28,
    lineHeight: 33,
    height: 66,
    opacity: 0
  }),
  async beforeRouteLeave(to, from, next) {
    const historyState = window.history.state;
    const prevHistoryPath = historyState?.back;
    // reset search params when user leave the product detail after add product to cart
    if (prevHistoryPath === '/b2b/search-products' && to.path === '/b2b/main/home') {
      await this.setSearchParamsBackToDefault();
    }
    next();
  },
  async ionViewDidEnter() {
    await this.getProductDetailData();
  },
  ionViewWillLeave() {
    if (this.showSimiliarProduct) {
      this.similiarProductItems = null;
    }
  },
  computed: {
    ...mapGettersProduct(['item', 'search', 'error']),
    ...mapGettersOosProduct(['oosList']),
    ...mapGettersCart(['cart']),
    getSpecsOosByKey() {
      return functions.sortListStrings(functions.getSpecsOosByKey(this.oosList?.oosList?.otherVariant));
    },
    getSpecsOosById() {
      return functions.getSpecsOosById(this.oosList?.oosList?.otherVariant);
    },
    isSample() {
      return this.search.isSample;
    },
    formattedWeight() {
      return this.state.orderWeight;
    },
    getSimiliarProductsData() {
      return this.similiarProductItems;
    }
  },
  methods: {
    ...mapActionsCart([
      ACTIONS_CART.ADD_ITEM_TO_CART,
      ACTIONS_CART.GET_CART_ITEMS,
      ACTIONS_CART.GET_BADGE_NUMBERS
    ]),
    ...mapActionsNotification([ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]),
    ...mapActionsOos([
      ACTION_OOS.GET_BUYER_PRODUCTS_RECOMMEND,
      ACTION_OOS.UPDATE_SEARCH,
      ACTION_OOS.RESET_PRODUCT_RECOMMEND
    ]),
    ...mapActionsProduct([ACTIONS_B2B.UPDATE_SEARCH]),
    ...mapMutationsProduct([MUTATIONS.RESET_CATEGORY]),

    async getProductDetailData() {
      const route = this.route;
      const state = this.state;
      const oosVar = state.oosVariable;
      const storage = this.$storage;
      state.selectedTab = route.query.tab;
      state.user = await storage.getUser();
      state.buyer = await storage.getSelectedCompany();
      const { data, error } = await this.getProductDetail({
        productId: +route.params.id,
        tenantId: state.user.tenant.id,
        buyerId: state.buyer.id
      });
      if (error.value) {
        await this.$router.push({ path: '/b2b/main/product-detail/error' });
        return;
      }
      state.item = data.value;
      await Promise.all([storage.getSelectedCompany()]).then((val) => {
        state.selectedCompany = val[0];
      });
      this.defaultSpecsSelected();
      state.isProductOos = state.item.skusData.every((sku) => sku.is_out_of_stock);
      if (state.skusSelected) {
        state.orderWeight = state.skusSelected.weight.toFixed(2);
        if (!state.is_back_order && state.skusSelected.total_available_quantity > 0) {
          state.orderWeight =
            state.skusSelected.weight > state.skusSelected.total_available_quantity
              ? state.skusSelected.total_available_quantity.toFixed(2)
              : state.skusSelected.weight.toFixed(2);
        }
        state.isOutOfStock = state.skusSelected.is_out_of_stock;
      } else {
        state.orderWeight = 0;
      }
      state.isShow = true;

      oosVar.mainCategoryId = this.search.mainCategoryId ? this.search.mainCategoryId : 'history';
      oosVar.isSingleVariant = state.item.is_single_variant;
      oosVar.productId = state.item.id;
      oosVar.tenantId = state.item.tenant_id;
      oosVar.buyerId = state.selectedCompany.id;
      if (state.isProductOos) {
        await this[ACTION_OOS.GET_BUYER_PRODUCTS_RECOMMEND](oosVar);
      }
      await this.handleDisplayShowMore();
      await this.handleReduceFontSize();
    },

    backHome() {
      this.router.back();
    },

    async handleCheckLatetestPrice() {
      await this.getLatestPrice();
      await this.addToCart();
    },

    async addToCart() {
      const state = this.state;
      CleverTap.onUserAddToCart({
        data: this.state.item,
        sku: this.state.skusSelected,
        orderQty: state.orderQuantity
      });
      if (this.latesNewPrice[0].price && state.skusSelected.price !== this.latesNewPrice[0].price) {
        this.setOpenModalPriceUpdate(true);
      } else {
        state.isAddToCartLoading = true;
        const cart =
          this.cart && this.cart.items.length !== 0
            ? this.cart
            : {
                items: [],
                delivery_date: getTomorrow(),
                delivery_time: '',
                description: '',
                po_number: '',
                standalone: 0
              };
        const item = { ...state.skusSelected, limit_price: undefined };
        delete item['__typename'];
        delete item['is_catch_weight'];
        item.order_qty = state.skusSelected.is_order_by_weight
          ? INPUT_QUANTITY_BY_WEIGHT
          : state.orderQuantity;
        item.order_weight = state.orderWeight;
        item.selected = true;
        // find index item in cart
        if (this.isSample) item.sample = true;
        item.product_name = state.item.name;
        item.product_id = state.item.id;
        item.tenant_id = state.item.tenant_id;
        item.tenant_name = state.item.tenant.tenant.name;
        item.tenant_class_id = state.item.tenant.class_id;
        item.tenant_email = state.item.tenant.email;
        (item.tenant_email_notification = state.item.tenant.email_notification === 1 ? true : false),
          (item.tenant_phone = state.item.tenant.tenant.persons[0]?.phone || null);
        item.selected = true;
        delete item.total_available_quantity;
        const params = {
          customer_id: state.selectedCompany.id,
          delivery_date: cart.delivery_date,
          delivery_time: cart.delivery_time,
          description: cart.description,
          po_number: cart.po_number,
          standalone: cart.standalone === 1 ? true : false,
          item
        };
        await this[ACTIONS_CART.ADD_ITEM_TO_CART](params);
        this[ACTIONS_NOTIFICATION.GET_BADGE_NUMBERS]({
          customerId: state.selectedCompany.id
        });
        this.openToast(this.$t('successfully_added_to_cart'));
        if (state.isOpenSelectOtherVariant) {
          state.isOpenSelectOtherVariant = false;
        }
        state.isAddToCartLoading = false;
        this.$router.replace({ path: '/b2b/main/home' });
      }
    },
    async showAlertErrPrice() {
      const alert = await alertController.create({
        header: 'Oops!',
        mode: 'ios',
        message: this.$t('something_went_wrong_please_try_again'),
        buttons: [
          {
            text: this.$t('OK'),
            handler: async () => {
              await this.detectConnection();
              await this.handleGetCustomerDetail();
            }
          }
        ]
      });
      await alert.present();
    },
    async handleGetCustomerDetail() {
      const state = this.state;
      try {
        const { data } = await apolloClient.query({
          query: getCustomerDetailBuyer,
          variables: {
            id: state.selectedCompany.id
          }
        });
        await this.$storage.setSelectedCompany({
          ...state.selectedCompany,
          account_number: data.getCustomerDetailBuyer.account_number
        });
        const updateCompany = await this.$storage.getSelectedCompany();
        state.selectedCompany = updateCompany;
      } catch (error) {
        console.log(error);
      }
    },
    //Check latest items/product price
    async getLatestPrice() {
      const state = this.state;
      try {
        const { data } = await apolloClient.query({
          query: getLatestPrice,
          variables: {
            skuIds: state.skusSelected.sku_id,
            accountNumber: state.selectedCompany.account_number
          }
        });
        this.latesNewPrice = data.getLatestPrice;
      } catch (error) {
        this.showAlertErrPrice();
      }
    },

    //show price changes alert
    async setOpenModalPriceUpdate(params) {
      this.isOpenPriceUpdated = params;
      if (!params) {
        await this.getProductDetailData();
        await this.updateSkusSelectedById(this.state.skusSelected.sku_id);
      }
    },

    defaultSpecsSelected() {
      const state = this.state;
      if (state.item.skusData.length === 1 && !state.item.skusData[0].specs) {
        this.changeSkusSelected({ ...state.item.skusData[0], image: state.item.image });
        const parseSpecs = { Single_Variant: true };
        state.specsSelected = Object.values(parseSpecs);
        state.specsInvolved = Object.values(parseSpecs);
      }
    },
    handleShowHeader: debounce(function (event) {
      event.detail.scrollTop > 270 ? (this.state.isShowHeader = true) : (this.state.isShowHeader = false);
    }, 100),
    handleReduceFontSize() {
      const el = this.$refs.refNameProduct || null;
      if (el) {
        if (this.$refs.refNameProduct.$el.scrollHeight > this.height) {
          this.fontSize = this.fontSize - 3;
          this.lineHeight = this.lineHeight - 3;
          this.height = this.height - 6;
        } else {
          this.opacity = 1;
          this.state.isShow = false;
          this.$nextTick(() => {
            this.state.isShow = true;
          });
        }
      }
    },
    handleDisplayShowMore: debounce(function () {
      // add debounce 500 ms to wait until the all page view and ref is already rendered
      const el = this.$refs.refDescription || null;
      if (!el) return;
      this.$refs.refDescription.$el.scrollHeight > 48
        ? (this.state.isDisplayShowMore = true)
        : (this.state.isDisplayShowMore = false);
    }, 500),
    onInputWeight: function (event) {
      const state = this.state;
      const maxWeight = 999999.99;
      let value = event.target.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && value.slice(decimalIndex + 1).length > 2) {
        // truncate to two decimal places
        value = Math.trunc(parseFloat(value) * 100) / 100;
        event.target.value = value;
      }
      state.orderWeight = value;
      if (!this.isBackOrder && state.orderWeight > this.remainingQuantity) {
        state.orderWeight = this.remainingQuantity.toFixed(2);
        event.target.value = state.orderWeight;
        this.openToast(this.$t('out_of_max_weight', { weight: this.remainingQuantity.toFixed(2) }), 'danger');
      } else {
        if (this.formattedWeight > maxWeight) {
          this.openToast(this.$t('out_of_max_weight', { weight: maxWeight }), 'danger');
          state.orderWeight = maxWeight.toFixed(2);
          event.target.value = state.orderWeight;
        }
      }
    },
    minusQuantity() {
      const state = this.state;
      if (state.isOutOfStock) {
        return;
      }
      const quantity = +state.orderQuantity || 0;
      const increment = +state.skusSelected?.increment_qty;
      if (quantity <= increment) {
        state.orderQuantity = increment;
      } else if (
        isQuantityAMultipleOfIncrement({
          quantity: quantity,
          increment: increment
        })
      ) {
        state.orderQuantity -= increment;
      } else {
        state.orderQuantity -= quantity % increment;
      }
    },
    addQuantity() {
      const state = this.state;
      if (state.isOutOfStock) {
        return;
      }
      const quantity = +state.orderQuantity || 0;
      const increment = +state.skusSelected?.increment_qty;
      if (isLargerMaxInput(quantity + increment)) {
        state.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder) {
          if (quantity < this.remainingQuantity) {
            state.orderQuantity = quantity + increment - (quantity % increment);
          } else {
            this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
            state.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
          }
        } else {
          state.orderQuantity = quantity + increment - (quantity % increment);
        }
      }
    },
    orderQuantityChange() {
      // process check input value
      const state = this.state;
      if (!state.orderQuantity) return;

      state.orderQuantity = Number(`${state.orderQuantity}`.replace(/[^\d]/g, '')) || 0;

      if (isLargerMaxInput(state.orderQuantity)) {
        state.orderQuantity = MAX_INPUT;
      } else {
        if (!this.isBackOrder && state.orderQuantity > this.remainingQuantity) {
          const increment = Number(state.skusSelected.increment_qty);
          this.openToast(this.$t('out_of_max_quantity', { quantity: this.remainingQuantity }), 'danger');
          state.orderQuantity = this.remainingQuantity - (this.remainingQuantity % increment);
        }
      }
      state.orderQuantity = `${state.orderQuantity}`;
    },
    updateSkusSelectedById(id) {
      const state = this.state;
      if (id === 0) {
        this.changeSkusSelected();
      } else {
        const index = state.item?.skusData.findIndex((skus) => skus.sku_id == id);
        let data = {};
        if (index !== -1) {
          data = {
            ...state.item?.skusData[index],
            image: state.item?.image
          };
        }
        this.changeSkusSelected(data);
      }
    },
    async updateLocalRequestQuotation() {
      const state = this.state;
      if (this.error) {
        await this.showAlert({
          header: this.$t('quotationPendingTitle'),
          message: this.error.message
        });
        return;
      }
      this.changeSkusSelected({
        ...state.skusSelected,
        quotation_status: QUOTATION_STATUS.REQUESTED
      });
      const index = state.item?.skusData.findIndex((skus) => skus.sku_id == state.skusSelected.sku_id);
      state.item.skusData[index].quotation_status = QUOTATION_STATUS.REQUESTED;
      await this.showAlert({
        header: this.$t('success'),
        message: this.$t('quotation_has_been_sent')
      });
    },
    async setOtherVariantModal(value) {
      const state = this.state;
      state.isOpenSelectOtherVariant = value;
      if (state.isOpenSelectOtherVariant) {
        this.defaultOosSpecsSelected();
      } else {
        this.defaultSpecsSelected();
        this.checkSpecItem(state.specsSelected[0]);
      }
    },
    async unCheckSpecItem(spec) {
      const state = this.state;
      const list = this.getSkusInfo;
      const index = state.specsSelected.indexOf(spec);
      if (index !== -1) {
        // remove 1 spec -> length - 1
        state.specsSelected.splice(index, 1);
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
          state.specsSelected,
          list
        );
        state.specsInvolved = specsInvolved;
        state.skuIdsInvolved = skuIdsInvolved;

        // check if specsInvolved lenght = specsSelected length -> clear specsSelected
        if (state.specsSelected.length + 1 === state.specsInvolved.length) {
          state.specsSelected = [];
          const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
            state.specsSelected,
            list
          );
          state.specsInvolved = specsInvolved;
          state.skuIdsInvolved = skuIdsInvolved;
        }
      }
      this.updateSkusSelectedById(0);
    },
    async checkSpecItem(value) {
      const state = this.state;
      const specs = [...state.specsSelected, value];
      const list = this.getSkusInfo;
      const { skuIds, specsSelected } = functions.findSkuFromSpecs(specs, list);
      if (skuIds.length === 1) {
        this.updateSkusSelectedById(skuIds[0]);
        state.specsSelected = specsSelected;
        state.specsInvolved = specsSelected;
      } else {
        state.specsSelected = specs;
        const { specsInvolved, skuIdsInvolved } = functions.getSpecsInvolvedBySpecs(
          state.specsSelected,
          list
        );
        state.specsInvolved = specsInvolved;
        state.skuIdsInvolved = skuIdsInvolved;
      }
    },
    showOosAndBuyAgainStatus(value) {
      const state = this.state;
      const list = this.getSkusInfo;
      const result = {
        showOos: true,
        showBuyAgain: false
      };

      // check oos status
      if (!state.specsInvolved.length || state.specsInvolved.includes(value)) {
        list.forEach((item) => {
          if (
            (!state.skuIdsInvolved.length || state.skuIdsInvolved.includes(item.skuId)) &&
            item.specs.includes(value) &&
            !item.isOos
          ) {
            result.showOos = false;
          }
          if (
            (!state.skuIdsInvolved.length || state.skuIdsInvolved.includes(item.skuId)) &&
            !result.showOos &&
            item.isPurchased &&
            item.specs.includes(value)
          )
            result.showBuyAgain = true;
        });
      } else {
        result.showOos = false;
      }

      return result;
    },

    scrollToSimiliarProducts() {
      const contentElement = this.$refs.productDetailContent.$el;
      const similarProductElement = this.$refs.similiarProduct;

      if (contentElement && similarProductElement) {
        const targetOffsetTop = similarProductElement.offsetTop - 65;
        const targetOffsetLeft = similarProductElement.offsetLeft;

        contentElement.scrollToPoint(targetOffsetLeft, targetOffsetTop, 500);
      }
    },

    async handlePendingApprove() {
      this.state.skusSelected.quotation_status = QUOTATION_STATUS.REQUESTED;
      await this.reloadProductDetails();
      await this.updateSkusSelectedById(this.state.skusSelected.sku_id);
    },
    updateLocalFavorite() {
      const state = this.state;
      const newFavorite = !state.skusSelected.is_favorite;
      this.changeSkusSelected({
        ...state.skusSelected,
        is_favorite: newFavorite
      });

      state.item.skusData.forEach((skus) => {
        if (skus.sku_id == state.skusSelected.sku_id) {
          skus.is_favorite = newFavorite;
        }
      });
      state.isChangeSkuFavorite = true;
      this.refreshFavoriteList();
    },

    refreshFavoriteList() {
      // if (this.list.category === 'favourite') {
      //   this.onSearch('favourite');
      // }
    },
    async onSelectSimilarProduct(item) {
      CleverTap.onUserViewRecommendProduct(item);

      // reset oosList
      await this[MUTATIONS.RESET_PRODUCT_RECOMMEND]();

      this.$router.push({
        path: `/b2b/main/product-detail/${item.id}`
      });
    },
    async onExploreOtherProducts() {
      this.state.specsSelected = [];
      this.state.specsInvolved = [];
      this.state.skusSelected = null;
      this.state.isOutOfStock = false;
      this.$router.push({ path: '/b2b/main/home' });
    },
    async openSocialSharing() {
      const deviceInfo = await Device.getInfo();
      CleverTap.onUserShareProduct(this.state.item);
      await this.Share.share({
        title: this.$t('product_detail.sharing_title', { product_name: this.state.item.name }),
        text: this.$t('product_detail.sharing_text', { product_name: this.state.item.name }),
        url: `${process.env.VUE_APP_PRODUCT_SHARING_URL}/?url=main/product-detail/${this.state.item.id}`,
        dialogTitle: this.$t('product_detail.sharing_dialog_title')
      }).then(() => {
        CleverTap.onUserShareProductSuccess(deviceInfo);
      });
    },

    async setSearchParamsBackToDefault() {
      await this[MUTATIONS.RESET_CATEGORY]();
      await this[ACTIONS_B2B.UPDATE_SEARCH]({
        search: {
          ...this.search,
          searchQueries: '',
          mainCategoryId: 'history',
          sortBy: 'purchased',
          sortType: 'desc',
          categories: [],
          offset: 0
        },
        type: TYPE_SEARCH.CATEGORY
      });
    },

    async reloadProductDetails() {
      const state = this.state;
      const route = this.route;
      const isSkuIds = this.route.query.skuIds;
      const payload = {
        productId: route.params.id,
        tenantId: state.user.tenant.id,
        buyerId: state.buyer.id,
        ...(isSkuIds && {
          skuIds: Array.isArray(this.route.query.skuIds)
            ? this.route.query.skuIds.map((item) => +item)
            : [+this.route.query.skuIds]
        })
      };
      const { data } = await this.getProductDetail(payload);
      state.item = data;
      this.defaultSpecsSelected();
      if (state.skusSelected) {
        state.orderWeight = state.skusSelected.weight.toFixed(2);
        if (!state.is_back_order && state.skusSelected.total_available_quantity > 0) {
          state.orderWeight =
            state.skusSelected.weight > state.skusSelected.total_available_quantity
              ? state.skusSelected.total_available_quantity.toFixed(2)
              : state.skusSelected.weight.toFixed(2);
        }
      } else {
        state.orderWeight = 0;
      }
    }
  },
  watch: {
    async 'state.specsSelected'() {
      const state = this.state;
      const oosVar = this.state.oosVariable;
      if (state.isOutOfStock) {
        this.checkOtherVariation = true;
        await this[ACTION_OOS.GET_BUYER_PRODUCTS_RECOMMEND](oosVar);
        setTimeout(() => {
          this.checkOtherVariation = false;
        }, 1000);
      }
    },
    async 'state.isProductOos'() {
      const state = this.state;
      const oosVar = this.state.oosVariable;
      if (state.isProductOos) {
        this.checkOtherVariation = true;
        await this[ACTION_OOS.GET_BUYER_PRODUCTS_RECOMMEND](oosVar);
        setTimeout(() => {
          this.checkOtherVariation = false;
        }, 1000);
      }
    },
    oosList() {
      this.similiarProductItems = this.oosList?.oosList?.items;
      if ((this.showSimiliarProduct || this.state.isProductOos) && this.similiarProductItems?.length > 0) {
        this.scrollToSimiliarProducts();
      }
    },
    fontSize() {
      setTimeout(() => {
        this.handleReduceFontSize();
      }, 50);
    },
    $ionIsActive(isActive) {
      if (isActive) {
        this.reloadProductDetails(isActive);
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
