<template>
  <ion-button
    :disabled="!allowAttach"
    class="btn-not-request mr-0"
    color="primary"
    expand="block"
    @click="requestQuotation"
  >
    <span class="ml-1">{{ $t('request_quotation') }}</span>
  </ion-button>

  <ion-loading
    :is-open="isOpenLoadingref"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
    @didDismiss="setOpenLoading(false)"
  >
  </ion-loading>
</template>
<script>
import { IonButton, IonLoading } from '@ionic/vue';
import { createNamespacedHelpers } from 'vuex';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/b2b/store/product/actions';
import { NOTIFICATION_TYPE } from '@/modules/b2b/constants';
import { ref } from 'vue';

const { mapActions: mapActionsProduct } = createNamespacedHelpers('b2b/product');

export default {
  components: {
    IonButton,
    IonLoading
  },

  props: {
    selectedCompanyId: {
      type: Number,
      required: true
    },
    tenantId: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    skuId: {
      type: Number,
      required: true
    },
    customer: {
      type: String,
      required: true
    },
    allowAttach: {
      type: Boolean,
      required: true
    }
  },

  emits: ['update-local-request-quotation'],

  setup() {
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      NOTIFICATION_TYPE,
      isOpenLoadingref,
      setOpenLoading
    };
  },

  methods: {
    ...mapActionsProduct([ACTIONS_PRODUCT.REQUEST_QUOTATION]),
    async requestQuotation() {
      this.setOpenLoading(true);
      await this[ACTIONS_PRODUCT.REQUEST_QUOTATION]({
        payload: {
          userId: this.userId,
          customerBuyerId: this.selectedCompanyId,
          skuId: this.skuId,
          tenantId: this.tenantId,
          targetOnly: true,
          notificationType: NOTIFICATION_TYPE.NEW_QUOTATION,
          customer: this.customer
        }
      });
      this.setOpenLoading(false);
      this.$emit('update-local-request-quotation');
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-not-request {
  margin: 0.5rem;
  height: 45px;
  --border-radius: 10px;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
}
</style>
