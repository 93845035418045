<template>
  <ion-button
    :disabled="!allowAttach || specsOosSelected < 1"
    @click="setFavorite"
    fill="clear"
    :color="isFavorite ? 'red' : 'grey4'"
  >
    <ion-img slot="icon-only" :src="isFavorite ? favorite : unfavorite"></ion-img>
  </ion-button>
</template>
<script>
import unfavorite from '@/assets/icons/shared/unfavorite.png';
import favorite from '@/assets/icons/shared/favorite.png';
import { createNamespacedHelpers } from 'vuex';
import { ACTIONS as ACTIONS_PRODUCT } from '@/modules/b2b/store/product/actions';

const { mapActions: mapActionsProduct } = createNamespacedHelpers('b2b/product');
export default {
  props: {
    isFavorite: {
      type: Boolean,
      required: true
    },
    selectedCompany: {
      type: Object,
      required: true
    },
    skusSelected: {
      type: Object,
      required: true
    },
    allowAttach: {
      type: Boolean,
      required: true
    },
    specsOosSelected: {
      type: Number,
      required: false
    }
  },

  emits: ['update-local-favorite'],

  setup() {
    return {
      unfavorite,
      favorite
    };
  },

  methods: {
    ...mapActionsProduct([ACTIONS_PRODUCT.ADD_FAVORITE, ACTIONS_PRODUCT.REMOVE_FAVORITE]),
    // toggle favorite
    setFavorite() {
      if (this.isFavorite) {
        // call remove favorite
        this[ACTIONS_PRODUCT.REMOVE_FAVORITE]({
          item: {
            account_number: `${this.selectedCompany?.account_number}`,
            sku_id: this.skusSelected?.sku_id
          }
        });
      } else {
        // call add favorite
        this[ACTIONS_PRODUCT.ADD_FAVORITE]({
          item: {
            account_number: `${this.selectedCompany?.account_number}`,
            user_id: this.selectedCompany?.id,
            list_sku_id: [this.skusSelected?.sku_id]
          }
        });
      }

      this.$emit('update-local-favorite');
    }
  }
};
</script>
