<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar class="header-modal-update-price px-3">
        <ion-label class="fs-4 fw-bold" color="dark" slot="start">{{ $t('bargain_price') }}</ion-label>
        <ion-icon slot="end" :icon="closeOutline" @click="$emit('off-modal')"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="mx-4 ion-no-padding mt-4">
        <div class="d-flex sku-title">
          <td-image
            class="img-update-price"
            :image="item?.image"
            :imagePath="IMAGE_PATH.PRODUCT"
            :imageDefault="DEFAULT_PRODUCT_IMAGE"
          ></td-image>
          <div class="sku-name ml-2">
            <ion-label class="label-tenant mt-2">
              {{ item.tenant.tenant.name }}
            </ion-label>
            <ion-label class="title mt-1">{{ skusSelected.sku }}</ion-label>
            <div class="mt-1">
              <ion-label v-if="item?.halal" class="label-halal">{{ $t('sync_state.halal') }}</ion-label>
              <ion-label v-if="item?.halal === false" class="label-non-halal">{{
                $t('sync_state.non_halal')
              }}</ion-label>
            </div>
          </div>
        </div>
        <div class="sku-info mt-2">
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('packaging_size') }}</ion-label>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-label>{{ $t('weight') }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6" class="ion-no-padding">
              <ion-text>{{ packaging_size ? packaging_size : '' }}</ion-text>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
              <ion-text>{{ weight ? weight : '' }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="mb-2">
            <ion-col size="12" class="ion-no-padding">
              <ion-label>{{ $t('direct_price') }}</ion-label>
            </ion-col>
            <ion-col size="12" class="ion-no-padding dricet-price">
              <ion-text>{{ directPrice }}&nbsp;</ion-text>
              <ion-text>{{ `(${directPriceAboveKg})` }}</ion-text>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
      <div class="bg-gray my-1"></div>
      <ion-grid class="ion-no-padding mt-4 support_information mb-2">
        <ion-label class="mx-4 support">{{ $t('support_information') }}</ion-label>
        <ion-item class="mr-4" detail="false">
          <ion-textarea :placeholder="$t('input_proposed')" v-model="description"></ion-textarea>
        </ion-item>
      </ion-grid>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-toolbar mode="md" class="px-3">
        <ion-button
          :disabled="description.length < 1"
          fill="solid"
          class="my-2 send_btn"
          @click="handleBargainPrice"
          >{{ $t('send') }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/b2b/constants';
import { requestQuotation } from '@/modules/b2b/services/graphql';
import { MUTATIONS } from '@/modules/b2b/store/product/mutations';
import { errorAlert } from '@/utils/alert';
import { toastController } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { createNamespacedHelpers } from 'vuex';
const { mapMutations } = createNamespacedHelpers('b2b/product');
export default {
  props: [
    'skusSelected',
    'packaging_size',
    'weight',
    'item',
    'productImage',
    'directPrice',
    'directPriceAboveKg',
    'userId',
    'selectedCompanyId',
    'skuId',
    'tenantId',
    'customer'
  ],
  setup() {
    return {
      closeOutline,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE,
      errorAlert
    };
  },
  data() {
    return {
      description: ''
    };
  },

  methods: {
    ...mapMutations([MUTATIONS.UPDATE_STATUS_QUOTATION]),
    async openToast() {
      const toast = await toastController.create({
        message: this.$t('request_sent'),
        duration: 2000,
        mode: 'ios',
        color: 'dark'
      });
      return toast.present();
    },
    async handleBargainPrice() {
      try {
        await apolloClient.mutate({
          mutation: requestQuotation,
          variables: {
            payload: {
              userId: this.userId,
              customerBuyerId: this.selectedCompanyId,
              skuId: this.skuId,
              tenantId: this.tenantId,
              targetOnly: true,
              notificationType: 'new_quotation',
              description: this.description,
              customer: this.customer
            }
          }
        });
        this.$emit('handlePendingApprove');
        this.openToast();
        this.$emit('off-modal');
      } catch (e) {
        this.errorAlert(e.message);
        this.$emit('off-modal');
      }
    }
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
