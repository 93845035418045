<template>
  <div class="column card mx-3" @click="toProductDetail(item)">
    <ion-label class="mx-2 my-2 name">{{ item.name }}</ion-label>
    <div class="line-grey"></div>
    <ion-row>
      <ion-col class="ion-no-padding" size="auto">
        <div class="product-image">
          <div class="image-wrapper ml-1">
            <treedots-image
              :key="key"
              :type="'PRODUCT'"
              :image="item.image"
              :cssClass="'image-wrapper'"
              :isHalal="item.halal"
              :isOos="isOos"
            ></treedots-image>
          </div>
          <div v-if="item && item.halal" class="halal-label">
            <p class="halal">{{ $t('halal') }}</p>
          </div>
          <!-- Hide discound Label for now -->
          <!-- <div class="discount-label mt-2">
            <p class="discount">{{ discountPrice + ' OFF' }}</p>
          </div> -->
        </div>
      </ion-col>
      <ion-col class="ml-2">
        <div class="stock mt-2">{{ weightFn(item) }}</div>
        <div
          v-show="isSalesAdmin ? showQuotationLimitPrice || showPrice : showPrice"
          class="price-range my-1"
        >
          {{ priceDiscountFn(item, user?.country.currency_symbol) }}
        </div>
        <div v-show="isSalesAdmin ? showQuotationLimitPrice || showPrice : showPrice" class="unit-price">
          {{ priceFn(item, user?.country.currency_symbol) }}
        </div>
      </ion-col>
    </ion-row>
  </div>
  <div class="space"></div>
</template>
<script>
import TreedotsImage from '@/components/treedots-image';
import { discountFn, priceDiscountFn, priceFn, weightFn } from '@/modules/b2b/services/libs/home';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'NewProductItem',
  components: {
    TreedotsImage
  },
  emits: ['item-clicked'],
  props: ['key', 'item', 'user', 'selectedCompany', 'id'],

  watch: {
    'item.productAdditionalInfos'() {
      this.itemProductDetail = this.item.productAdditionalInfos;
      this.checkIsOos();
    }
  },

  mounted() {
    this.itemProductDetail = this.item.productAdditionalInfos;
    this.checkIsOos();
  },

  computed: {
    //show price from quotation limit price for sales/admin user when customer has no direct price
    isSalesAdmin() {
      return this.user?.user_type_id === 2 || this.user?.user_type_id === 7;
    },

    showQuotationLimitPrice() {
      return this.isSalesAdmin && (this.item?.max_price > 0 || this.item?.min_price > 0);
    },

    showPrice() {
      return (
        (this.item.quotation_statuses && this.item.quotation_statuses.split(',').includes('received')) ||
        this.item.hasDirectPrice
      );
    }
  },

  setup() {
    const itemProductDetail = ref(null);
    const isOos = ref(false);

    return {
      itemProductDetail,
      isOos,
      discountFn,
      priceDiscountFn,
      weightFn,
      priceFn
    };
  },

  methods: {
    toProductDetail(item) {
      this.$emit('item-clicked', item);
    },

    checkIsOos() {
      this.isOos = this.itemProductDetail?.is_oos;
    }
  }
});
</script>

<style lang="scss" scoped>
.column {
  display: flex;
  flex-direction: column;
}
.card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  widows: 100%;
  height: fit-content;
  min-height: 156px;
}
.image-wrapper {
  height: 120px;
  width: 120px;
  border-radius: 0px 0px 0px 8px;
  overflow: hidden;
}
.line-grey {
  height: 1.2px;
  width: 100%;
  background-color: #e0e0e0;
}
.product-image::part(image) {
  position: relative;
  height: 120px;
  width: 120px;
  overflow: hidden;
  display: block;
  border-radius: 0px 0px 0px 8px;
}
ion-img {
  display: block;
  border-radius: 0px 0px 0px 8px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.halal-label {
  position: absolute;
  margin-bottom: -0.5px;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 18px;
  background: #12b76a;
  border-radius: 0px 0px 0px 8px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.halal {
  margin-top: 2px;
}
.oos {
  margin-top: -1px;
}
.discount {
  font-weight: 700;
  font-size: 10px;
  margin-top: -0.1px;
}
.discount-label {
  position: absolute;
  height: 18px;
  min-width: 58px;
  max-width: 60px;
  left: 0px;
  right: 0px;
  top: 12px;
  gap: 10px;
  padding-top: 2px;
  margin-left: -8px;
  text-align: center;
  color: #ffffff;
  border-radius: 6px 6px 6px 0px;
  background: #d92d20;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
.name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #212121;
}
.stock {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #757575;
}
.price-range {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.38px;
  color: #212121;
}
.unit-price {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #039855;
}

.space {
  height: 16px;
  width: 100%;
  background: transparent;
  background-color: transparent;
  color: transparent;
}
</style>
